const sites = [
  {
    id: 0,
    name: "Megaman Battle Network 4 Mystery Data Tracker",
    link: "https://mmbn4-item-tracker-1.onrender.com/",
    github: "https://github.com/Flumanuck/Mmbn4-Item-Tracker",
    image: require("../images/Mmbn4-tracker-screenshot.png"),
    description:
      "This is a webapp designed to help the uer keep track of Blue Mystery Data and Purple Mystery data, the items found in Megaman battle network, to help the user track what items they do and do not have in their playhrough. This site uses a login system so that you can keep track of your items even over mobile devices.",
    alt: "MMBN4 Item Tracker Screenshot",
  },
  {
    id: 1,
    name: "Definition and Translation App",
    link: "https://main.d916lbd27q0kh.amplifyapp.com/",
    github: "https://github.com/Flumanuck/Definition-Translation-App",
    image: require("../images/Translation-app-screenshot.png"),
    description:
      "This is an application in which the user can input any English word and get a brief definition as well as a Japanese translation. Japanese to English functionality to be added.",
    alt: "Note Taker Website screenshot",
  },
  {
    id: 2,
    name: "Python Chatbot",
    link: "https://github.com/Flumanuck/python-chatbot",
    github: "https://github.com/Flumanuck/python-chatbot",
    image: require("../images/Chatbot-Screenshot.png"),
    description:
      "This is a console based application using python that allows you to speak with and recieve vocal responses from an AI chatbot with the personality and voice of the user's choice",
    alt: "Python chatbot screenshot",
  },
  {
    id: 3,
    name: "Shantae Merch Tracker",
    link: "https://flumanuck.github.io/Shantae-Tracker",
    github: "https://github.com/Flumanuck/Shantae-Tracker",
    image: require("../images/Screenshot-Merch.png"),
    description:
      "This is a website that allows the user to track what official Shantae merchandise they have and do not have by compiling them in a list. (Note that this is only a proof of concept and merchandise will be added to it periodically)",
    alt: "Shantae Merch Tracker merchandise page",
  },
];

export default sites;
