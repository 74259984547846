import "./navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <h1 className="name">Jacob Machlis</h1>
    </nav>
  );
}

export default Navbar;
